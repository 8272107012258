import Store from '@/shared/store';
import { RoleType } from '../utils/config';

type roleData = {
  roles: boolean[];
  courseName: string;
  teamName: string;
};

export const roles: Array<string> = Store.namespace('auth').get('roles') ?? [];
export const teamName: string = Store.namespace('auth').get('teamName');
export const courseName: string = Store.namespace('auth').get('courseName');

export const hasRole = (role: RoleType | string) => {
  return (
    roles.includes(role) ||
    (roles.includes(RoleType.Admin) && role != 'student')
  );
};

export const storeRoles = (data: roleData) => {
  const roles: string[] = [];

  Object.values(RoleType)
    .filter((_, i) => data.roles[i])
    .forEach((rt) => {
      roles.push(rt);
    });

  if (roles.length === 0) {
    roles.push('student');
  }

  Store.namespace('auth').set('roles', roles);

  Store.namespace('auth').set('teamName', data.teamName);

  Store.namespace('auth').set('courseName', data.courseName);

  return roles;
};

export default {
  teamName,
  courseName,
  storeRoles,
  hasRole,
  RoleType,
};
