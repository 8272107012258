var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',[(_vm.base.location !== undefined && ['Team'].includes(_vm.base.location))?_c('div',[(_vm.base.locationDetails)?_c('a',{staticClass:"osu-link",attrs:{"href":'/teams/' + _vm.base.locationDetails}},[_vm._v(_vm._s(_vm.base.locationDetails.split('_').pop())+" ")]):_vm._e()]):(
        _vm.base.location !== undefined &&
        ['TA', 'Store', 'Other'].includes(_vm.base.location)
      )?_c('div',{class:{
        'truncate-wrap': _vm.truncate && _vm.wrap,
        'truncate-nowrap': _vm.truncate && !_vm.wrap,
      }},[_vm._v(" "+_vm._s(_vm.base.locationDetails)+" ")]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }