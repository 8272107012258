<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error returning tool! <b>{{ failureReason }}</b> Try again later.
    </b-alert>

    <b-form @submit="onSubmit">
      <div show>
        Do you want to force return the tool
        <span class="font-weight-bold">{{ loan.item }}</span> on loan to
        <span class="font-weight-bold">{{ loan.team?.name }}</span
        >? <br /><br />
      </div>

      <div style="float: right">
        <b-btn type="submit" variant="danger" :disabled="disableSubmit">
          Return Tool
        </b-btn>
      </div>
    </b-form>

    <b-modal
      id="controller-update-form"
      title="Controller Update Form"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <EditControllerForm
        v-if="controller"
        v-on:edit-success="editSuccess"
        :controller="controller"
        :teams="teams"
        :users="users"
        :fromLoansPage="true"
      />
    </b-modal>
    <b-modal
      id="proteus-update-form"
      title="Proteus Update Form"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <EditProteusForm
        v-if="controller"
        v-on:edit-success="editSuccess"
        :proteus="controller"
        :teams="teams"
        :users="users"
        :fromLoansPage="true"
      />
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import loans from '../../api/loans';
import EditControllerForm from '../../components/controllers/EditControllerForm.vue';
import EditProteusForm from '../../components/protei/EditProteusForm.vue';
import controllers from '@/api/controllers';
import protei from '@/api/protei';
import teams from '@/api/teams';
import users from '@/api/users';

@Component({
  components: {
    EditControllerForm,
    EditProteusForm,
  },
})
export default class ForceReturnForm extends Vue {
  @Prop(Object) loan!: Loan;

  teams: Team[] = [];
  users: User[] = [];

  keycode = '';
  keycodeFailureReason = '';
  failureReason = '';

  validKeycode = false;

  showSuccess = false;
  showFailure = false;
  showKeycodeFailure = false;

  disableSubmit = false;

  controller: Controller | Proteus | null = null;

  get tool() {
    return this.loan.isTool ? this.loan.tool?.name : this.loan.part?.name;
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.disableSubmit = true;
    const controllerType = this.loan.item;
    if (controllerType.includes('ERC2') || controllerType.includes('PROTEUS')) {
      if (controllerType.includes('ERC2')) {
        controllers.getController(this.loan.description).then((res) => {
          if (res.success && res.data?.status == 'success') {
            this.controller = res.data.controller;
          } else {
            this.showFailure = true;
            this.failureReason =
              'ERC2 not found. Proceeding to return other items.';
          }
        });
      } else if (controllerType.includes('PROTEUS')) {
        protei.getProteus(this.loan.description).then((res) => {
          if (res.success && res.data?.status == 'success') {
            this.controller = res.data.proteus;
          } else {
            this.showFailure = true;
            this.failureReason =
              'Proteus not found. Proceeding to return other items.';
          }
        });
      }
      if (
        this.failureReason.includes('Proteus') ||
        this.failureReason.includes('Controller')
      ) {
        this.editSuccess();
        return;
      }

      teams.getTeams().then((res) => {
        if (res.success) {
          this.teams = res.data;
        }
      });
      users.getUsers().then((res) => {
        if (res.success) {
          this.users = res.data;
        }
      });
      if (controllerType.includes('ERC2')) {
        this.$bvModal.show('controller-update-form');
      } else {
        this.$bvModal.show('proteus-update-form');
      }
    } else {
      this.editSuccess();
    }
  }

  editSuccess() {
    this.$bvModal.hide('controller-update-form');
    this.$bvModal.hide('proteus-update-form');
    this.disableSubmit = false;

    if (this.controller != null) {
      this.$emit('update');
      return;
    }

    loans.checkIn([this.loan.id]).then((res) => {
      if (res.success && res.data?.status == 'success') {
        this.showSuccess = true;
        this.$emit('update');
      } else {
        this.showFailure = true;
        this.failureReason =
          res.data?.message || res.error?.message || 'Unknown error';
      }
      this.disableSubmit = false;
    });
  }

  clearForm() {
    this.keycode = '';
  }
}
</script>
