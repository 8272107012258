<template>
  <div>
    <b-container>
      <b-row align-v="center">
        <b-col>
          <h2>Controller Details</h2>
        </b-col>
        <b-col class="text-right">
          <b-btn
            class="m-2"
            v-if="admin || igta || store || controllerDeveloper"
            variant="danger"
            v-b-modal.edit-controller-modal
            >✏️ &nbsp; Edit</b-btn
          >
          <b-btn variant="danger" href="/controllers">Back to List</b-btn>
        </b-col>
      </b-row>
    </b-container>
    <hr />
    <div v-if="controller">
      <b-container class="p-0">
        <b-row no-gutters>
          <b-col cols="4">
            <div class="font-weight-bold mt-2">Number</div>
            {{ controller.number }}
            <div class="font-weight-bold mt-2">Status</div>
            {{ controller.status }}
            <div class="font-weight-bold mt-2">Last Updator:</div>
            {{ controller.lastUpdatedByUserName }}
            <div class="font-weight-bold mt-2">Last Updated:</div>
            {{ getFormattedDate(controller.updatedAt) }}
            <div class="font-weight-bold mt-2">Location</div>
            {{ controller.location }}
            <div class="font-weight-bold mt-2">Location Details</div>
            <ControllerLocationDetail :base="controller" :truncate="false" />
          </b-col>
          <b-col v-if="controllerDeveloper" cols="8">
            <div class="font-weight-bold mt-2">Note</div>
            <b-textarea
              v-model="form.note"
              class="lazy"
              @change="editControllerNote"
              rows="7"
            ></b-textarea>
          </b-col>
        </b-row>
      </b-container>
      <hr />
      <b-card no-body class="mt-2">
        <b-tabs card>
          <b-tab title="Updates" active title-link-class="osu-link">
            <ControllerUpdateTable :updates="controller.updates">
            </ControllerUpdateTable>
          </b-tab>
          <div v-if="controllerDeveloper">
            <b-tab title="Outstanding Issues" title-link-class="osu-link">
              <ControllerIssueTable
                :controller="controller"
                :issues="
                  (controller.issues ?? []).filter((issue) => !issue.resolved)
                "
              />
            </b-tab>
            <b-tab title="Resolved Issues" title-link-class="osu-link">
              <ControllerIssueTable
                :controller="controller"
                :issues="
                  (controller.issues ?? []).filter((issue) => issue.resolved)
                "
              />
            </b-tab>
          </div>
        </b-tabs>
      </b-card>
    </div>

    <b-modal id="edit-controller-modal" title="Update Controller" hide-footer>
      <EditControllerForm
        v-on:edit-success="editSuccess"
        :controller="controller"
        :teams="teams"
        :users="users"
      ></EditControllerForm>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { failToast, successToast, formatDate } from '@/utils/genericUtils';
import { Component, Vue } from 'vue-property-decorator';
import EditControllerForm from '../../components/controllers/EditControllerForm.vue';
import ControllerLocationDetail from '../../components/controllers/ControllerLocationDetail.vue';
import ControllerIssueTable from '../../components/controllers/ControllerIssueTable.vue';
import ControllerUpdateTable from '../../components/controllers/ControllerUpdateTable.vue';
import roles from '../../shared/roles';
import users from '../../api/users';
import controllers from '../../api/controllers';
import teams from '../../api/teams';

@Component({
  components: {
    EditControllerForm,
    ControllerLocationDetail,
    ControllerIssueTable,
    ControllerUpdateTable,
  },
})
export default class ViewControllers extends Vue {
  controller: Controller | null = null;
  users: User[] | null = null;
  teams: Team[] | null = null;

  update() {
    controllers.getController(this.$route.params.number).then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res.success) {
        this.controller = res.data.controller;
        this.resetForm();
      } else {
        failToast(this, 'Failed to get controller, please try again later.');
      }
    });
    users.getUsers().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res?.success) {
        this.users = res.data;
      } else {
        failToast(this, 'Failed to get users, please try again later.');
      }
    });
    teams.getTeams().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res?.success) {
        this.teams = res.data;
      } else {
        failToast(this, 'Failed to get teams, please try again later.');
      }
    });
  }

  mounted() {
    this.update();
  }

  editSuccess() {
    this.$bvModal.hide('edit-controller-modal');
    this.update();
    successToast(this, 'Controller updated!');
  }

  get admin() {
    return roles.hasRole(roles.RoleType.Admin);
  }

  get igta() {
    return roles.hasRole(roles.RoleType.InstructorGTA);
  }

  get controllerDeveloper() {
    return roles.hasRole(roles.RoleType.ControllerDeveloper);
  }

  get store() {
    return (
      roles.hasRole(roles.RoleType.Store) ||
      roles.hasRole(roles.RoleType.StoreLead)
    );
  }

  form = { note: '' };

  resetForm() {
    if (this.controller) {
      this.form = { note: this.controller.note };
    }
  }

  getFormattedDate(dateString: string) {
    return formatDate(dateString);
  }

  editControllerNote() {
    const updates = { note: this.form.note, id: this.controller?.id ?? '' };
    controllers.putController(updates).then((res) => {
      if (res.success) {
        successToast(this, 'Successfully edited note!');
        this.update();
      } else {
        failToast(this, 'Failed to edit note, please try later.');
      }
    });
  }
}
</script>
