<template>
  <div>
    <h2>Services</h2>
    <hr />
    <!-- <b-alert show variant="danger">
      The service submission page is currently down for maintenance. Check back
      later to submit your service requests. If you have previously submitted
      service orders, these will still be completed during this down time.
    </b-alert> -->
    <b-card no-body>
      <b-tabs card>
        <b-tab v-if="true" title="3D Printing" active>
          <PRAddProduct></PRAddProduct>
        </b-tab>
        <b-tab v-else title="3D Printing" active>
          <PRAddProductSelfReport></PRAddProductSelfReport>
        </b-tab>
        <b-tab title="Laser Cutting">
          <LCAddProduct></LCAddProduct>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import PRAddProduct from '../../components/3d_printing/PRAddProduct.vue';
import PRAddProductSelfReport from '../../components/3d_printing/PRAddProductSelfReport.vue';
import LCAddProduct from '../../components/laser_cutting/LCAddProduct.vue';
import roles from '@/shared/roles';
@Component({
  components: { PRAddProduct, PRAddProductSelfReport, LCAddProduct },
})
export default class AddService extends Vue {
  get isFE() {
    return roles.courseName.includes('ENGR 1182');
  }
}
</script>
