<template>
  <div>
    <TableWrapper :all="controllerHistory" v-on:pagingChange="pagingChange">
      <b-table
        id="controllers-table"
        striped
        hover
        :items="controllerHistory"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :current-page="paging.current"
        :per-page="paging.per"
        responsive="sm"
      >
        <template #cell(controller)="data">
          <b-link
            :disabled="!proteusDeveloper && !store"
            :href="`/controllers/${data.value}/details`"
            class="osu-link"
            >{{ data.value.number }}</b-link
          >
        </template>

        <template #cell(startDate)="data">
          {{ new Date(data.value).toLocaleString() }}
        </template>

        <template #cell(endDate)="data">
          <span v-if="!data.value">
            {{ 'Current' }}
          </span>
          <span v-else>
            {{ new Date(data.value).toLocaleString() }}
          </span>
        </template>
      </b-table>
    </TableWrapper>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import OrdersSearch from '../../components/orders/OrdersSearch.vue';
import TableWrapper from '../utils/TableWrapper.vue';
import teams from '../../api/teams';
import roles from '@/shared/roles';

@Component({ components: { OrdersSearch, TableWrapper } })
export default class TeamControllerTable extends Vue {
  @Prop(String) teamId!: string;

  controllerHistory = [];

  paging = { per: 1, current: 1, total: 1 };
  pagingChange(incoming: Paging) {
    this.paging = incoming;
  }

  sortBy = 'endDate';
  sortDesc = true;
  fields = [
    { key: 'controller', label: 'Number', sortable: false },
    { key: 'startDate', label: 'Start Date', sortable: true },
    { key: 'endDate', label: 'End Date', sortable: true },
  ];

  get proteusDeveloper() {
    return roles.hasRole(roles.RoleType.ProteusDeveloper);
  }

  get store() {
    return (
      roles.hasRole(roles.RoleType.Store) ||
      roles.hasRole(roles.RoleType.StoreLead)
    );
  }

  mounted() {
    this.loadControllerHistory();
  }

  loadControllerHistory() {
    teams.getTeamControllerHistory(this.teamId).then((res) => {
      if (res.data) {
        this.controllerHistory = res.data;
      }
    });
  }

  update() {
    this.loadControllerHistory();
    this.$emit('update');
  }
}
</script>
