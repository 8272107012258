<template>
  <div>
    <b-alert variant="success" v-model="showSuccess" dismissible>
      {{ successes }} users successfully added!
    </b-alert>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      {{ failures }} users could not be added ({{ successes }} successful).
      Please check the <a href="#" @click.prevent="showReport = true">log</a>.
    </b-alert>

    <div v-if="showReport" class="report-container">
      <div class="report-content">
        <button class="close-btn" @click="showReport = false">✖</button>
        <p class="report-text">{{ report }}</p>
      </div>
    </div>

    <div>
      <p>
        Download
        <a href="#" @click="downloadUploadRosterTemplate">the .csv template</a>
        to get started.
        <span class="tooltip-container">
          <span class="question-mark" @click="toggleTooltip">?</span>
          <div v-if="showTooltip" class="tooltip-box">
            Don't remove the header row. To assign the store lead or admin<br />
            roles, create the user manually or edit their roles after
            creation.<br />
            If a user belongs to multiple sections, separate them with "/". If
            a<br />
            user belongs to multiple courses, use a new row for each course.
          </div>
        </span>
      </p>
    </div>

    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-file"
        label="User roster:"
        label-for="input-file"
      >
        <b-form-file
          id="input-file"
          v-model="form.file"
          required
          accept="text/csv"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          :state="Boolean(form.file)"
        ></b-form-file>
      </b-form-group>
      <b-button type="submit" variant="danger"> Add Users </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import { base64StringToFile } from '../../../utils/genericUtils';
import users from '../../../api/users';
import { RoleType } from '@/utils/config';

@Component({})
export default class ParseStaffForm extends Vue {
  @Prop(Array) readonly courses!: Course[];
  @Prop(Array) readonly sections!: Section[];

  form: { file: File | null; courseId: string } = {
    file: null,
    courseId: '',
  };

  showSuccess = false;
  showFailure = false;
  successes = 0;
  failures = 0;
  report = '';
  showReport = false;

  showTooltip = false;

  toggleTooltip() {
    this.showTooltip = !this.showTooltip;
  }

  uploadRosterTemplate: File | null = null;

  get courseOptions() {
    const result: { text: string; value: string }[] = [];

    this.courses.forEach((course) => {
      result.push({ text: course.name, value: course.id });
    });
    return result;
  }

  clearForm() {
    this.form = {
      file: null,
      courseId: '',
    };
  }

  downloadUploadRosterTemplate() {
    users.getRosterTemplate().then((res) => {
      if (!res.success) {
        console.log('Failed to get roster template');
        return;
      }

      const blob = base64StringToFile(res.data?.src, 'csv');
      const fileURL = window.URL.createObjectURL(blob);
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', 'UploadUsers.csv');
      document.body.appendChild(fileLink);
      fileLink.click();
    });
  }

  onSubmit(evt: Event) {
    evt.preventDefault();

    if (!this.form.file) return;

    this.successes = 0;
    this.failures = 0;
    this.report = '';

    const userPosts: UserPost[] = [];

    const reader = new FileReader();
    reader.readAsArrayBuffer(this.form.file);
    reader.addEventListener('load', async (ev) => {
      const result = ev.target?.result as ArrayBuffer | null | undefined;

      if (!result) return;

      const decoder = new TextDecoder('utf-8');
      const text = decoder.decode(result);

      const flag = '***';

      text
        .trim()
        .split(/\r?\n/)
        .slice(1)
        .map((a) => a.split(','))
        .forEach((r) => {
          const [nameNumber, firstName, lastName, c, s] = r.slice(0, 5);

          const course = this.courses.find((val) => val.name === c)?.id;

          if (!course) {
            this.report += `Warning: Invalid course for ${nameNumber}: ${c}.\n`;
            this.failures++;
            return;
          }

          const rawSections = s
            .split('/')
            .filter((code) => code)
            .map((code) => {
              const sec = this.sections.find((val) => val.name === code);
              if (sec && sec.course?.id === course) {
                return sec.id;
              } else {
                return code + flag;
              }
            });

          const invalidSections = rawSections
            .filter((s) => s.endsWith(flag))
            .map((s) => s.slice(0, -flag.length));

          if (invalidSections.length) {
            invalidSections.forEach(
              (s) =>
                (this.report += `Warning: Invalid section for ${nameNumber}: ${s}.\n`),
            );
            this.failures++;
            return;
          }

          const roleFlags = r.slice(5);

          // no one has admin or store lead roles
          roleFlags.splice(0, 0, '');
          roleFlags.splice(2, 0, '');
          const roles = Object.values(RoleType).filter(
            (_, i) => roleFlags[i].toLowerCase() === 'y',
          );

          const userPost: UserPost = {
            courseId: course,
            username: nameNumber,
            firstName: firstName,
            lastName: lastName,
            sections: rawSections,
            roles: roles,
          };

          userPosts.push(userPost);
        });

      Promise.all(
        userPosts.map(async (userPost) => {
          const data = await users.postUser(userPost);

          if (data.success) {
            this.report += `Successfully added user ${userPost.username}.\n`;
            this.successes++;
          } else {
            this.report += `Failed to add user ${userPost.username}.\n`;
            this.failures++;
          }
        }),
      ).then(() => {
        if (this.failures > 0) {
          this.showFailure = true;
        } else {
          this.showSuccess = true;
        }

        this.$emit('update');
      });
    });
  }
}
</script>

<style scoped>
.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 8px;
}

.question-mark {
  display: inline-block;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #cacccf;
  color: white;
  border-radius: 50%;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
  user-select: none;
}

.tooltip-box {
  position: absolute;
  top: 30px;
  right: 0;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
  white-space: nowrap;
}

.report-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f8f9fa;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 1rem;
  z-index: 1000;
}

.report-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 50vh;
  overflow-y: auto;
  width: 100%;
  padding: 1rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.report-text {
  white-space: pre-line;
  margin: 0;
  flex-grow: 1;
}

.close-btn {
  align-self: flex-end;
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: #333;
}

.close-btn:hover {
  color: #000;
}
</style>
