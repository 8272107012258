<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error returning tools! <b>{{ failureReason }}</b> Try again later.
    </b-alert>

    <b-alert variant="danger" v-model="showKeycodeFailure" dismissible>
      Error with key code! {{ keycodeFailureReason }}
    </b-alert>

    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-key-code"
        label-for="input-part-key-code"
        class="mt-1"
      >
        <b-form-input
          id="input-part-key-code"
          v-model="keycode"
          placeholder="Keytag Number"
          :state="validKeycode"
          required
          autofocus
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-loaned-items"
        label-for="input-loaned-items"
      >
        <vue-multiselect
          id="input-loaned-items"
          v-model="returningItems"
          :options="options"
          :multiple="true"
          :searchable="false"
          :close-on-select="false"
          :clear-on-select="false"
          :show-labels="false"
          label="label"
          track-by="value"
          placeholder="Pick a item to return"
        ></vue-multiselect>
      </b-form-group>
      <b-form-group v-if="hasProteus">
        <b-form-checkbox
          id="input-verified-proteus"
          v-model="verifiedProteusReturn"
          >Verify that the Proteus case includes the Proteus, SD card, USB
          Adapter, charger, and stylus.
          <strong
            >You (the TA) are responsible for ensuring that everything is
            returned!</strong
          ></b-form-checkbox
        >
      </b-form-group>
      <b-form-group v-if="hasController">
        <b-form-checkbox
          id="input-verified-controller"
          v-model="verifiedControllerReturn"
        >
          Verify that the ERC2 case includes the ERC2, USB Cables, charger, and
          stylus.
          <strong
            >You (the TA) are responsible for ensuring that everything is
            returned!</strong
          >
        </b-form-checkbox>
      </b-form-group>
      <b-row>
        <b-col style="m-4">
          <b-btn @click="fillMostRecentTeam">Most Recent Team</b-btn>
        </b-col>
        <b-col>
          <div style="float: right">
            <b-btn
              type="submit"
              variant="danger"
              :disabled="
                returningItems.length == 0 ||
                disableSubmit ||
                (hasProteus && !verifiedProteusReturn)
              "
            >
              Return Tools
            </b-btn>
          </div>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id="controller-update-form"
      title="Controller Update Form"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <EditControllerForm
        v-if="controller"
        v-on:edit-success="editSuccess"
        :controller="controller"
        :teams="teams"
        :users="users"
        :fromLoansPage="true"
      />
    </b-modal>
    <b-modal
      id="proteus-update-form"
      title="Proteus Update Form"
      hide-footer
      hide-header-close
      no-close-on-backdrop
      no-close-on-esc
    >
      <EditProteusForm
        v-if="controller"
        v-on:edit-success="editSuccess"
        :proteus="controller"
        :teams="teams"
        :users="users"
        :fromLoansPage="true"
      />
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import api from '../../api/loans';
import {
  getMostRecentKeycode,
  setMostRecentKeycode,
} from '../../utils/genericUtils';
import { MAX_KEYCODE_LENGTH } from '../../utils/config';
import EditControllerForm from '../../components/controllers/EditControllerForm.vue';
import EditProteusForm from '../../components/protei/EditProteusForm.vue';
import controllers from '@/api/controllers';
import protei from '@/api/protei';
import teams from '@/api/teams';
import users from '@/api/users';

@Component({
  components: {
    EditControllerForm,
    EditProteusForm,
  },
})
export default class ReturnForm extends Vue {
  @Prop(Array) tools!: Tool[];

  returningItems: { label: string; value: string }[] = [];

  controller: Controller | Proteus | null = null;
  controllerNumber: string = '';
  teams: Team[] = [];
  users: User[] = [];

  options: { label: string; value: string }[] = [];
  itemIds: string[] = [];

  keycode = '';
  keycodeFailureReason = '';
  failureReason = '';

  validKeycode = false;

  showSuccess = false;
  showFailure = false;
  showKeycodeFailure = false;

  disableSubmit = false;

  verifiedProteusReturn = false;
  verifiedControllerReturn = false;

  get hasProteus() {
    this.verifiedProteusReturn = false;
    return this.returningItems.some((item) => item.label.includes('PROTEUS'));
  }

  get hasController() {
    this.verifiedControllerReturn = false;
    return this.returningItems.some((item) => item.label.includes('ERC2'));
  }

  @Watch('keycode')
  onKeycodeChanged(val: string) {
    this.returningItems = [];
    this.options = [];
    this.validKeycode = false;

    if (val.length <= MAX_KEYCODE_LENGTH && Number.isInteger(Number(val))) {
      api.getLoansByKeycode(val).then((res) => {
        if (res.success && res.data?.status == 'success') {
          this.validKeycode = true;
          this.showKeycodeFailure = false;
          this.keycodeFailureReason = '';
          const loans = res.data.loans;

          loans.forEach((elem: Loan) => {
            if (elem.lost) {
              return;
            } else if (elem.tool) {
              let label = elem.tool.name;
              if (elem.tool.requireItemNumber) {
                label += ' (' + elem.description + ')';
              }
              this.options.push({
                label: label,
                value: elem.id,
              });
              if (elem.tool.name == 'ERC2' || elem.tool.name == 'PROTEUS') {
                this.controllerNumber = elem.description;
              }
            } else if (elem.part) {
              this.options.push({
                label: elem.part.partKey + ': ' + elem.part.name,
                value: elem.id,
              });
            }
          });
        } else {
          this.showKeycodeFailure = true;
          this.keycodeFailureReason =
            res.data?.message || res.error?.message || 'Unknown error';
        }
      });
    } else if (val.length > 0) {
      this.showKeycodeFailure = true;
      this.keycodeFailureReason = `Keycodes are integers with ${MAX_KEYCODE_LENGTH} digits.`;
    } else {
      this.showKeycodeFailure = false;
      this.keycodeFailureReason = '';
    }
  }

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.disableSubmit = true;
    this.itemIds = [];
    let controllerLoanId = '';
    let controllerType = '';
    this.returningItems.forEach((elem) => {
      if (elem.label.includes('ERC2') || elem.label.includes('PROTEUS')) {
        controllerType = elem.label;
        controllerLoanId = elem.value as string;
      } else {
        this.itemIds.push(elem.value as string);
      }
    });

    if (controllerLoanId && this.controllerNumber) {
      if (controllerType.includes('ERC2')) {
        controllers.getController(this.controllerNumber).then((res) => {
          if (res.success && res.data?.status == 'success') {
            this.controller = res.data.controller;
            // this.itemIds.push(controllerLoanId);
          } else {
            this.showFailure = true;
            this.failureReason =
              'Controller not found. Proceeding to return other items.';
          }
        });
      } else {
        protei.getProteus(this.controllerNumber).then((res) => {
          if (res.success && res.data?.status == 'success') {
            this.controller = res.data.proteus;
            // this.itemIds.push(controllerLoanId);
          } else {
            this.showFailure = true;
            this.failureReason =
              'Proteus not found. Proceeding to return other items.';
          }
        });
      }

      if (
        this.failureReason.includes('Proteus') ||
        this.failureReason.includes('Controller')
      ) {
        this.editSuccess();
        return;
      }

      teams.getTeams().then((res) => {
        if (res.success) {
          this.teams = res.data;
        }
      });
      users.getUsers().then((res) => {
        if (res.success) {
          this.users = res.data;
        }
      });
      if (controllerType.includes('ERC2')) {
        this.$bvModal.show('controller-update-form');
      } else {
        this.$bvModal.show('proteus-update-form');
      }
    } else {
      this.editSuccess();
    }
  }

  editSuccess() {
    this.$bvModal.hide('controller-update-form');
    this.$bvModal.hide('proteus-update-form');
    if (this.itemIds.length == 0) {
      this.disableSubmit = false;
      setMostRecentKeycode(this.keycode);
      this.$emit('update');
      return;
    }
    api.checkIn(this.itemIds).then((res) => {
      if (res.success && res.data?.status == 'success') {
        setMostRecentKeycode(this.keycode);
        this.$emit('update');
      } else {
        this.showFailure = true;
        this.failureReason =
          res.data?.message || res.error?.message || 'Unknown error';
      }
      this.disableSubmit = false;
    });
  }

  clearForm() {
    this.keycode = '';
  }

  fillMostRecentTeam() {
    this.keycode = getMostRecentKeycode();
  }
}
</script>
