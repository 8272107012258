import { del, get, post, put } from '../shared/requests';

const getControllers = async () => {
  return await get('/controllers');
};

const getController = async (controllerNum: string) => {
  return await get(`/controllers/${controllerNum}`);
};

const getControllersForTeam = async (teamId: string) => {
  return await get(`/controllers/team/${teamId}`);
};

const postController = async (controller: Controller) => {
  return await post('/controllers', controller);
};

const postControllerUpdate = async (
  update: ControllerUpdate,
  issue: { issuePrompts: string[]; lastStudentAction: string } | null,
) => {
  return await post(`/controllers/${update.id}/updates`, { ...update, issue });
};

const putIssue = async (issue: {
  id: string;
  resolved: boolean;
  issueTypes: string[];
}) => {
  return await put(`/controllers/issues/${issue.id}`, issue);
};

const getIssue = async (id: string) => {
  return await get(`/controllers/issues/${id}`);
};

const putController = async (controller: { id: string; note: string }) => {
  return await put(`/controllers/${controller.id}`, controller);
};

const deleteController = async (controller: Controller) => {
  return await del(`/controllers/${controller.id}`);
};

export default {
  getControllers,
  getController,
  getControllersForTeam,
  postController,
  postControllerUpdate,
  putIssue,
  putController,
  getIssue,
  deleteController,
};
