<template>
  <div>
    <h2>Teams</h2>
    <hr />

    <div v-if="admin || igta">
      <b-btn v-b-modal.add-team-modal class="m-2" variant="danger"
        >Add Team</b-btn
      >
      <b-btn v-b-modal.add-student-modal class="m-2" variant="danger"
        >Add Student</b-btn
      >
      <b-btn v-b-modal.add-roster-modal class="m-2" variant="danger"
        >Add Roster</b-btn
      >

      <b-btn
        v-b-modal.delete-all-teams-modal
        class="m-2"
        variant="danger"
        style="float: right"
        >Delete All Teams</b-btn
      >
    </div>

    <b-form-group
      v-if="courseOptions.length > 1"
      class="mb-2 mr-2 mt-2 d-flex align-items-center"
    >
      <b-form-select
        id="course-filter"
        v-model="shownCourse"
        :options="courseOptions"
      ></b-form-select>
    </b-form-group>

    <div class="mt-2">
      <b-table
        striped
        hover
        :no-local-sorting="true"
        :items="shownTeams"
        :fields="fields"
        @sort-changed="onSortChange"
        responsive="sm"
      >
        <template #cell(name)="data">
          <b-button
            variant="link"
            @click="goToTeamPage(data.item)"
            style="padding: 0"
            class="osu-link"
            >{{ data.value.split('_').pop() }}</b-button
          >
        </template>

        <template #cell(course)="data">
          <div v-if="data.item.section.courseId">
            {{ buildCourseString(data.item.section.courseId) }}
          </div>
        </template>

        <template #cell(section)="data">
          <div v-if="data.value">
            {{ buildSectionString(data.value) }}
          </div>
        </template>

        <template #cell(remBudget)="data">
          <div>
            {{ formatBudget(data.value) }}
          </div>
        </template>

        <template #cell(proteus)="data">
          <div v-if="data.value">
            <b-link
              v-if="proteusDeveloper"
              :href="`/protei/${data.value.number}/details`"
              class="osu-link"
              >Proteus {{ data.value.number }}</b-link
            >
            <div v-else>Proteus {{ data.value.number }}</div>
          </div>
          <div
            v-else-if="
              data.item.controllers && data.item.controllers.length > 0
            "
          >
            <b-link
              :href="`/controllers/${data.item.controllers[0].number}/details`"
              class="osu-link"
              :disabled="!store && !controllerDeveloper"
              >Controller {{ data.item.controllers[0].number }}</b-link
            >
          </div>
          <div v-else>N/A</div>
        </template>

        <template #cell(students)="data">
          <div v-for="student in data.value" :key="student.id">
            • {{ student.firstName }}
            {{ capitalizeFirstLetter(student.username) }}
          </div>
        </template>
      </b-table>
    </div>

    <b-modal id="add-team-modal" title="Add Team" hide-footer>
      <AddTeamForm
        :courses="courses"
        :sections="sections"
        v-on:update="update"
      ></AddTeamForm>
    </b-modal>
    <b-modal id="add-student-modal" title="Add Student" hide-footer>
      <AddStudentForm
        :teams="teams"
        :courses="courses"
        v-on:update="update"
      ></AddStudentForm>
    </b-modal>
    <b-modal id="add-roster-modal" title="Upload Roster" hide-footer>
      <ParseTeamsForm
        :sections="sections"
        v-on:update="update"
      ></ParseTeamsForm>
    </b-modal>
    <b-modal id="delete-all-teams-modal" title="Delete All Teams" hide-footer>
      <DeleteAllTeamsForm
        :teamCount="teamCount"
        v-on:update="onDeleteAllTeamsSuccess"
      ></DeleteAllTeamsForm>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Watch } from 'vue-property-decorator';
import AddTeamForm from '../../components/teams/AddTeamForm.vue';
import AddStudentForm from '../../components/users/students/AddStudentForm.vue';
import ParseTeamsForm from '../../components/teams/ParseTeamsForm.vue';
import DeleteAllTeamsForm from '../../components/teams/DeleteAllTeamsForm.vue';
import teams from '../../api/teams';
import sections from '../../api/sections';
import courses from '../../api/courses';
import { INF_BUDGET } from '../../utils/config';
import {
  buildSectionString,
  successToast,
  capitalizeFirstLetter,
} from '../../utils/genericUtils';
import roles from '../../shared/roles';

@Component({
  components: {
    AddTeamForm,
    AddStudentForm,
    ParseTeamsForm,
    DeleteAllTeamsForm,
  },
  methods: {
    buildSectionString,
    capitalizeFirstLetter,
  },
})
export default class ViewTeams extends Vue {
  teams: Team[] | null = null;
  shownTeams: Team[] | null = null;
  sections: Section[] | null = null;
  courses: Course[] | null = null;

  sortBy = 'name';
  sortDesc = false;

  shownCourse = roles.courseName.split(',')[0];

  get courseOptions() {
    return (
      this.teams
        ?.map((team) => team.section?.course?.name)
        ?.filter((value, index, self) => self.indexOf(value) === index) ?? []
    );
  }

  get fields() {
    const base = [
      { key: 'name', sortable: true },
      {
        key: 'section',
        sortable: true,
      },
      { key: 'remBudget', sortable: true, label: 'Remaining Budget' },
      { key: 'students', sortable: false },
    ];

    if (this.shownCourse === 'ENGR 1282.01H') {
      base.splice(3, 0, {
        key: 'proteus',
        sortable: false,
        label: 'Proteus/Controller',
      });
    }

    return base;
  }

  onSortChange({ sortBy, sortDesc }: { sortBy: string; sortDesc: boolean }) {
    this.sortBy = sortBy;
    this.sortDesc = sortDesc;

    const sortedTeams = this.shownTeams ? [...this.shownTeams] : [];

    if (sortBy === 'name') {
      sortedTeams.sort(this.sortName);
    } else if (sortBy === 'course') {
      sortedTeams.sort(this.sortCourse);
    } else if (sortBy === 'section') {
      sortedTeams.sort(this.sortSection);
    } else if (sortBy === 'remBudget') {
      sortedTeams.sort((a, b) => {
        const budgetA = a.remBudget ?? 0;
        const budgetB = b.remBudget ?? 0;
        return this.sortDesc ? budgetB - budgetA : budgetA - budgetB;
      });
    }
    this.shownTeams = sortedTeams;
  }

  sortName(a: Team, b: Team) {
    const nameA = a.name?.split('_').pop()?.toLowerCase() ?? '';
    const nameB = b.name?.split('_').pop()?.toLowerCase() ?? '';

    if (
      nameA.localeCompare(nameB, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) < 0
    )
      return this.sortDesc ? 1 : -1;
    if (
      nameA.localeCompare(nameB, undefined, {
        numeric: true,
        sensitivity: 'base',
      }) > 0
    )
      return this.sortDesc ? -1 : 1;
    return 0;
  }

  sortCourse(a: Team, b: Team) {
    const courseA = this.buildCourseString(
      a.section?.courseId ?? '',
    ).toLowerCase();
    const courseB = this.buildCourseString(
      b.section?.courseId ?? '',
    ).toLowerCase();
    if (courseA < courseB) return this.sortDesc ? 1 : -1;
    if (courseA > courseB) return this.sortDesc ? -1 : 1;
    return 0;
  }

  sortSection(a: Team, b: Team) {
    const sectionA = buildSectionString(a.section).toLowerCase();
    const sectionB = buildSectionString(b.section).toLowerCase();
    if (sectionA < sectionB) return this.sortDesc ? 1 : -1;
    if (sectionA > sectionB) return this.sortDesc ? -1 : 1;
    return 0;
  }

  @Watch('shownCourse', { immediate: true })
  onShownCourseChanged(val: string) {
    this.shownCourse = val;
    this.shownTeams =
      this.teams?.filter((team) => {
        return team.section?.course?.name == this.shownCourse;
      }) ?? [];
    this.shownTeams!.sort(this.sortName);
  }

  get admin() {
    return roles.hasRole(roles.RoleType.Admin);
  }

  get igta() {
    return roles.hasRole(roles.RoleType.InstructorGTA);
  }

  get ta() {
    return roles.hasRole(roles.RoleType.TA);
  }

  get proteusDeveloper() {
    return roles.hasRole(roles.RoleType.ProteusDeveloper);
  }

  get controllerDeveloper() {
    return roles.hasRole(roles.RoleType.ControllerDeveloper);
  }

  get store() {
    return (
      roles.hasRole(roles.RoleType.Store) ||
      roles.hasRole(roles.RoleType.StoreLead)
    );
  }

  goToTeamPage(team: Team) {
    // Store.namespace('cache').set('teamId', team.id);
    this.$router.push(`/teams/${team.name}`).catch(() => {});
  }

  update() {
    if (
      this.admin ||
      this.proteusDeveloper ||
      this.controllerDeveloper ||
      this.igta
    ) {
      this.loadAllTeams();
      this.loadSections();
      this.loadCourses();
    } else if (this.ta) {
      this.loadTeamsInstructed();
    } else {
      this.$router.push('/forbidden');
    }
  }

  loadAllTeams() {
    teams.getTeams().then((res) => {
      //auth check
      if (res.status === 403) {
        this.$router.push('/forbidden');
      }
      this.teams = res.data;
      this.shownTeams = this.teams;
      if (!this.teams || !this.shownTeams) {
        return;
      }

      this.shownCourse = roles.courseName.split(',')[0];
      this.shownTeams = this.shownTeams.filter((team) => {
        return team.section?.course?.name == this.shownCourse;
      });

      this.shownTeams!.sort(this.sortName);
    });
  }

  loadTeamsInstructed() {
    teams.getTeamsInstructed().then((res) => {
      //auth check
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.teams = res.data;
      this.shownTeams = this.teams;
      this.shownTeams!.sort(this.sortName);
    });
  }

  loadSections() {
    sections.getSections().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.sections = res.data;
    });
  }

  loadCourses() {
    courses.getCourses().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.courses = res.data;
    });
  }

  mounted() {
    this.update();
  }

  buildCourseString(courseId: string) {
    if (!this.courses || courseId === '') {
      return 'N/A';
    }
    return this.courses.find((course) => course.id === courseId)?.name ?? 'N/A';
  }

  formatBudget(amt: number | undefined) {
    if (amt === undefined || amt === null) {
      return 'N/A';
    } else if (INF_BUDGET - amt < amt) {
      return `$${(INF_BUDGET - amt).toFixed(2)}`;
    } else {
      return `$${amt.toFixed(2)}`;
    }
  }

  onDeleteAllTeamsSuccess() {
    this.$bvModal.hide('delete-all-teams-modal');
    this.update();
    successToast(this, 'Successfully Deleted All Teams');
  }

  get teamCount() {
    return this.teams?.length ?? 0;
  }
}
</script>
