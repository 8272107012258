import { del, get, post, put } from '../shared/requests';

const getFilament = async (name: string) => {
  return await get(`/teams/filament/${name}`);
};

const getTeams = async () => {
  return await get('/teams/');
};

const getTeamsInstructed = async () => {
  return await get('/teams/instructed');
};

const getTeamControllerHistory = async (idOrName: string) => {
  return await get(`/teams/controllers/${idOrName}`);
};

const getTeamByIdOrName = async (idOrName: string) => {
  return await get(`/teams/${idOrName}`);
};

const putTeam = async (teamId: string, team: Team) => {
  return await put(`/teams/${teamId}`, team);
};

const postTeam = async (name: string, sectionId: string) => {
  return await post('/teams/', { name, sectionId });
};

const getRosterTemplate = async () => {
  return await get('/teams/batch/template');
};

const postRoster = async (
  rosterPostData: Record<string, string | Array<Record<string, string>>>,
) => {
  return await post('/teams/batch', rosterPostData);
};

const deleteTeam = async (teamId: string) => {
  return await del(`/teams/${teamId}`);
};

const deleteAllTeams = async () => {
  return await del('/teams/all');
};

export default {
  getFilament,
  getTeams,
  getTeamsInstructed,
  getTeamByIdOrName,
  getTeamControllerHistory,
  postTeam,
  putTeam,
  getRosterTemplate,
  postRoster,
  deleteTeam,
  deleteAllTeams,
};
