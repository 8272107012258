<template>
  <div>
    <b-alert variant="danger" v-model="showFailure" dismissible>
      Error updating material! Try again later.
    </b-alert>

    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-name"
        label="Name:"
        label-size="lg"
        label-for="input-name"
      >
        <b-form-input
          id="input-name"
          v-model="form.name"
          required
          placeholder="Material Name for Student Selection"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-file-name"
        label="File Name:"
        label-size="lg"
        label-for="input-file-name"
      >
        <b-form-input
          id="input-file-name"
          v-model="form.fileName"
          required
          placeholder="Name to use for file referencing"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        id="input-group-course"
        label="Course:"
        label-size="lg"
        label-for="input-course"
      >
        <b-form-select
          id="input-course"
          v-model="form.courseId"
          :options="courseOptions"
          :valid="!!form.courseId"
          required
        ></b-form-select>
      </b-form-group>

      <b-form-group
        id="input-group-cost"
        label="Cost:"
        label-size="lg"
        label-for="input-cost"
      >
        <b-row>
          <b-col cols="6">
            <b-form-group
              v-if="form.cost"
              id="input-group-cost-weight"
              label="Weight:"
              label-for="input-cost-weight"
            >
              <b-form-input
                id="input-cost-weight"
                v-model="form.cost.weight"
                type="number"
                min="0"
                step="0.01"
                @change="roundWeightCost(form.cost.weight)"
                @validate="checkCost"
              ></b-form-input>
            </b-form-group>
          </b-col>
        </b-row>
      </b-form-group>

      <b-form-group
        id="input-group-valid-until"
        label="Value Until:"
        label-size="lg"
        label-for="input-valid-until"
      >
        <b-form-datepicker
          id="input-valid-until"
          v-model="form.validUntil"
          :min="minDate"
          locale="en"
          placeholder="Material Valid Until"
        ></b-form-datepicker>
      </b-form-group>
      <hr />
      <b-button type="submit" variant="danger"> Edit Material </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from 'vue-property-decorator';
import materials from '@/api/materials';
import { ProductType } from '@/utils/config';
import { roundCost } from '@/utils/genericUtils';

@Component({})
export default class EditPRMaterialForm extends Vue {
  @Prop(Array) readonly courses!: Course[];
  @Prop(Object) readonly material!: Material;

  minDate = new Date();

  form: RecursivePartial<Material> = {
    name: '',
    fileName: '',
    service: ProductType.Printed,
    cost: {
      weight: 0,
    },
    courseId: '',
    validUntil: undefined,
  };

  showFailure = false;
  disableSubmit = false;

  get courseOptions() {
    const result: { text: string; value: string }[] = [];

    this.courses.forEach((course) => {
      result.push({ text: course.name.toUpperCase(), value: course.id });
    });

    return result;
  }

  clearForm() {
    this.form = {
      name: this.material.name,
      fileName: this.material.fileName,
      service: this.material.service,
      cost: {
        weight: this.material.cost.weight,
      },
      courseId: this.material.courseId,
      validUntil: this.material.validUntil,
    };
  }

  roundWeightCost(value?: number) {
    this.form.cost!.weight = roundCost(value!);
  }

  checkCost() {
    return this.form.cost!.weight != undefined && this.form.cost!.weight > 0;
  }

  mounted() {
    this.clearForm();
  }

  onSubmit(evt: Event) {
    if (!this.material.id) {
      return;
    }

    evt.preventDefault();
    this.showFailure = false;
    this.disableSubmit = true;

    materials.editMaterial(this.material.id, this.form).then((data) => {
      if (data.success) {
        this.clearForm();
        this.$emit('edit-success');
      } else {
        this.showFailure = true;
      }
      this.disableSubmit = false;
    });
  }
}
</script>
