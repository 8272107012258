import { render, staticRenderFns } from "./EditLCMaterialForm.vue?vue&type=template&id=96ed44ec"
import script from "./EditLCMaterialForm.vue?vue&type=script&lang=ts"
export * from "./EditLCMaterialForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports