export const DEBUG = process.env.NODE_ENV === 'development';

export const baseURL = DEBUG
  ? 'http://localhost:7001'
  : process.env.VUE_APP_API_ORIGIN;

export const studentFileS3BucketUrl =
  process.env.VUE_APP_S3_BUCKET_STUDENT_FILES;

export const sortOptions = [
  { text: 'Name', value: 0 },
  { text: 'Price: Low to High', value: 1 },
  { text: 'Price: High to Low', value: 2 },
];

export const statusOptions = [
  { text: 'Rejected', value: 'Rejected' },
  { text: 'Placed', value: 'Placed' },
  { text: 'Filled', value: 'Filled' },
  { text: 'Picked Up', value: 'Picked Up' },
];

// this enum mirrors the one in api/database/models/roles.
// if you make a change here, make sure you update the other file.
export enum RoleType {
  Admin = 'Admin',
  Store = 'Store',
  StoreLead = 'Store Lead',
  TA = 'TA',
  InstructorGTA = 'Instructor/GTA',
  ProteusDeveloper = 'Proteus Developer',
  ControllerDeveloper = 'Controller Developer',
  LaserCutting = 'Laser Cutting',
  Printing3D = '3D Printing',
  LabPersonnel = 'Lab Personnel',
}

export enum ProductType {
  Printed = 'Printed',
  LaserCut = 'LaserCut',
}

export enum OrderItemStatus {
  Rejected = 'Rejected',
  Placed = 'Placed',
  Filled = 'Filled',
  PickedUp = 'Picked Up',
  PartiallyFilled = 'Partially Filled',
  InProgress = 'In Progress',
}

export enum ReturnItemStatus {
  Requested = 'Requested',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

/* PROTEUS ENUMS. TO BE PHASED OUT */
export enum ProteusStatus {
  Working = 'Working',
  Broken = 'Broken',
  Distributed = 'Distributed',
  Development = 'Development',
  Loaned = 'Loaned',
  Unknown = 'Unknown',
}

export const ProteusIssuePrompts = [
  'Proteus does not turn on or does not stay powered on',
  'Screen does not turn on',
  'Broken or malfunctioning SD port',
  'Broken acrylic',
  'Motor or I/O Port(s) broken (please note which)',
  'Problems running or loading code',
  'Other (please describe)',
];

export const ProteusIssueTypes = [
  'Power Issues',
  'LCD',
  'SD Port',
  'Voltage Regulator',
  'Acrylic',
  'Propeller',
  'XBee',
  'Motor Ports',
  'I/O Ports',
  'Accelerometer',
  'Running/Loading Code',
  'Unknown',
  'Uncategorized',
];

/* ARDUINO CONTROLLER TYPES */

export enum ControllerStatus {
  Working = 'Working',
  Broken = 'Broken',
  Distributed = 'Distributed',
  Development = 'Development',
  Unknown = 'Unknown',
}

export enum ControllerLocation {
  Store = 'Store',
  TA = 'TA',
  Team = 'Team',
  Other = 'Other',
}

export const ControllerIssuePrompts = [
  'Proteus does not turn on or does not stay powered on',
  'Screen does not turn on',
  'Broken or malfunctioning SD port',
  'Broken acrylic',
  'Motor or I/O Port(s) broken (please note which)',
  'Problems running or loading code',
  'Other (please describe)',
];

export const ControllerIssueTypes = [
  'Power Issues',
  'LCD',
  'SD Port',
  'Voltage Regulator',
  'Acrylic',
  'Propeller',
  'XBee',
  'Motor Ports',
  'I/O Ports',
  'Accelerometer',
  'Running/Loading Code',
  'Unknown',
  'Uncategorized',
];

export const pointerPoem =
  'aPointerIsAVariableThatHasAsItsValueTheAddressOfAnotherVariable';

export const MAX_KEYCODE_LENGTH = 5;

// "Infinite" Budget
export const INF_BUDGET = 9_999_999;
export const INF_FILAMENT = 9_999_999;

export default {
  DEBUG,
  INF_BUDGET,
  INF_FILAMENT,
  baseURL,
  statusOptions,
};
