export const DEBUG = process.env.NODE_ENV === "development"
export const FORCE_SYNC = process.env.FORCE_SYNC === "true"

export const PORT = 7001
export const STORE_EMAIL = "FEH.Apps.NoReply@gmail.com"

export const NO_IMAGE_SOURCE = `https://${process.env.S3_BUCKET_FILES_NAME}.s3.amazonaws.com/ImageNotFound.png`

//Mostly for testing. Also so we have users who can access the store if all of the users are deleted
export const BUILT_IN_ADMINS = [
  "vadlamani.12",
  "ahern.55",
  "kecskemety.1",
  "hostetler.169",
  "clingan.3",
  "beckley.71",
  "kasibhatla.6",
  "dave.107",
  "mclachlan.11"
]

export const COURSES = {
  "ENGR 1282.01H": "FEH_ROBOT",
  "ENGR 1282.02H": "FEH_RID",
  "ENGR 1282.04H": "FEH_IBE",
  "ENGR 1182": "FE",
  "ENGR 1182.01": "FE",
  "ENGR 1182.02": "FE",
  "ENGR 1188": "FE"
}

// (Possible) year-to-year changes
export const MAX_KEYCODE_LENGTH = 5

// "Infinite" Budget
export const INF_BUDGET = 9_999_999
export const INF_FILAMENT = 9_999_999
