<template>
  <div>
    <b-alert variant="success" v-model="showSuccess" dismissible>
      Part added to cart!
    </b-alert>
    <b-alert variant="secondary" show>
      <b>Important:</b> Make sure you have read the EED 3D Printing policy
      document.
    </b-alert>
    <p>
      Please use the following form to provide the 3D Printing Team the details
      of your order. You will need to submit this form multiple times for
      different parts. Refer to the policy document for more details.
    </p>
    <b-form @submit="onSubmit">
      <b-form-group
        id="input-group-description"
        label-cols-md="2"
        label="Description:"
        label-for="input-description"
      >
        <b-form-textarea
          id="input-description"
          v-model="form.description"
          required
          placeholder="Provide a short description of your part and what it will be used for."
          rows="3"
          max-rows="3"
        ></b-form-textarea>
      </b-form-group>
      <b-form-group
        id="input-group-material-type"
        label="Material Type:"
        label-for="input-material-type"
      >
        <b-form-select
          id="input-material-type"
          v-model="form.material"
          :options="materials"
          @validate="form.material !== null"
          plain
          required
        ></b-form-select>
      </b-form-group>
      <b-form-group
        id="input-group-price"
        label="Price*:"
        label-for="input-price"
      >
        <b-form-input
          id="input-price"
          v-model="form.price"
          required
          min="0"
          step=".01"
          :number="true"
          type="number"
        ></b-form-input>
      </b-form-group>
      <b-form-group
        id="input-group-hours"
        label="Hours:"
        label-for="input-hours"
      >
        <b-form-input
          id="input-hours"
          v-model="form.hours"
          required
          min="0"
          step=".01"
          :number="true"
          type="number"
        ></b-form-input>
      </b-form-group>
      <small>*Provided by Slicing Software</small>
      <hr />
      <b-button type="submit" variant="danger"> Add to cart </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import roles from '@/shared/roles';
import { Component, Vue } from 'vue-property-decorator';
import materials from '@/api/materials';
import { v4 } from 'uuid';
import { ProductType } from '@/utils/config';
import { failToast } from '@/utils/genericUtils';

@Component({})
export default class PRAddProductSelfReport extends Vue {
  showSuccess = false;

  materials: { text: string; value: null | Material }[] = [];

  form: {
    description: string;
    price: number;
    hours: number;
    material: null | Material;
  } = {
    description: '',
    price: 0,
    hours: 0,
    material: null,
  };

  mounted() {
    if (!roles.hasRole(roles.RoleType.Admin) && !roles.teamName) {
      this.$router.push('/forbidden');
    }
    let teamName = roles.teamName;
    if (!teamName) {
      teamName = 'Admin';
    }
    materials.getMaterials().then((res) => {
      this.materials = [{ text: 'Select a material', value: null }];
      res.data.forEach((material: Material & { course: Course }) => {
        const mat = material;
        mat.courseId = material.course.id;
        if (mat.service == ProductType.Printed) {
          this.materials.push({ text: mat.name, value: mat });
        }
      });
    });
  }

  clearForm() {
    this.form = {
      description: '',
      price: 0,
      hours: 0,
      material: null,
    };
  }

  async onSubmit(evt: Event) {
    evt.preventDefault();

    const id = v4();

    if (this.form.price <= 0.01 || this.form.hours <= 0) {
      failToast(this, 'Validation failed. Check that your values make sense');
      return;
    }

    this.$store.dispatch('clearCart');

    this.$store.dispatch('addProduct', {
      id: id,
      type: ProductType.Printed,
      data: {
        description: this.form.description,
        basePrice: this.form.price,
        hoursSpent: this.form.hours,
        material: this.form.material,
      },
      files: [],
    });

    this.clearForm();
    this.$router.push('/orders/cart').catch(() => {});
  }
}
</script>
