<template>
  <div>
    <div>
      <div
        v-if="base.location !== undefined && ['Team'].includes(base.location)"
      >
        <a
          v-bind:href="'/teams/' + base.locationDetails"
          class="osu-link"
          v-if="base.locationDetails"
          >{{ base.locationDetails.split('_').pop() }}
        </a>
      </div>
      <div
        v-else-if="
          base.location !== undefined &&
          ['TA', 'Store', 'Other'].includes(base.location)
        "
        v-bind:class="{
          'truncate-wrap': truncate && wrap,
          'truncate-nowrap': truncate && !wrap,
        }"
      >
        {{ base.locationDetails }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component({})
export default class ControllerLocationDetail extends Vue {
  @Prop(Object) readonly base!: BaseController;
  @Prop({ default: true }) readonly truncate!: boolean;
  @Prop({ default: false }) readonly wrap!: boolean;
}
</script>

<style scoped>
.truncate-nowrap {
  max-width: 25vw;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncate-wrap {
  max-width: 25vw;
}
</style>
