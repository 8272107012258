import { render, staticRenderFns } from "./AddStudentForm.vue?vue&type=template&id=33a56651"
import script from "./AddStudentForm.vue?vue&type=script&lang=ts"
export * from "./AddStudentForm.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports