<template>
  <div>
    <h2>Controller Management</h2>
    <hr />
    <b-btn
      v-b-modal.add-controller-modal
      class="m-2"
      variant="danger"
      v-if="controllerDeveloper"
      >Add Controller</b-btn
    >

    <div class="mt-2" v-if="controllers">
      <ControllerTable
        :controllers="controllers"
        v-on:select-controller="selectController"
      />
    </div>

    <b-modal id="add-controller-modal" title="Add Controller" hide-footer>
      <AddControllerForm
        v-on:add-success="addSuccess"
        v-on:update="update"
        :teams="teams"
        :users="users"
      ></AddControllerForm>
    </b-modal>

    <b-modal id="edit-controller-modal" title="Update Controller" hide-footer>
      <EditControllerForm
        v-on:edit-success="editSuccess"
        :controller="selectedController"
        :teams="teams"
        :users="users"
      ></EditControllerForm>
    </b-modal>

    <b-modal id="delete-controller-modal" title="Delete Controller" hide-footer>
      <DeleteControllerForm
        v-on:delete-success="deleteSuccess"
        :controller="selectedController"
      ></DeleteControllerForm>
    </b-modal>
  </div>
</template>

<script lang="ts">
import { failToast, successToast } from '@/utils/genericUtils';
import { Component, Vue } from 'vue-property-decorator';
import AddControllerForm from '../../components/controllers/AddControllerForm.vue';
import EditControllerForm from '../../components/controllers/EditControllerForm.vue';
import DeleteControllerForm from '../../components/controllers/DeleteControllerForm.vue';
import ControllerLocationDetail from '../../components/controllers/ControllerLocationDetail.vue';
import ControllerTable from '../../components/controllers/ControllerTable.vue';
import roles from '../../shared/roles';
import controllers from '../../api/controllers';
import users from '../../api/users';
import teams from '../../api/teams';

@Component({
  components: {
    AddControllerForm,
    EditControllerForm,
    ControllerLocationDetail,
    DeleteControllerForm,
    ControllerTable,
  },
})
export default class ViewControllers extends Vue {
  controllers: Controller[] | null = null;
  selectedController: Controller | null = null;
  users: User[] | null = null;
  teams: Team[] | null = null;

  selectController(c: Controller) {
    this.selectedController = c;
  }

  update() {
    controllers.getControllers().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res.data?.status == 'success') {
        this.controllers = res.data.controllers;
      } else {
        failToast(
          this,
          'Failed to retreive controllers, please try again later.',
        );
      }
    });
    users.getUsers().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res.success) {
        this.users = res.data;
      } else {
        failToast(this, 'Failed to retreive users, please try again later.');
      }
    });
    teams.getTeams().then((res) => {
      if (res.status === 403) {
        this.$router.push('/forbidden');
        return;
      }
      if (res.success) {
        this.teams = res.data;
      } else {
        failToast(this, 'Failed to retreive teams, please try again later.');
      }
    });
  }

  mounted() {
    this.update();
  }

  addSuccess() {
    this.$bvModal.hide('add-controller-modal');
    successToast(this, 'Controller added!');
    this.update();
  }

  editSuccess() {
    this.$bvModal.hide('edit-controller-modal');
    successToast(this, 'Controller updated!');
    this.update();
  }

  deleteSuccess() {
    this.$bvModal.hide('delete-controller-modal');
    successToast(this, 'Controller deleted!');
    this.update();
  }

  get controllerDeveloper() {
    return roles.hasRole(roles.RoleType.ControllerDeveloper);
  }
}
</script>
