<template>
  <div>
    <b-form @submit="onSubmit">
      <b-alert variant="danger" show
        >Are you sure you want to delete this controller
        <span class="font-weight-bolder">{{ controller.number }}</span
        >?
      </b-alert>
      <b-button type="submit" variant="danger" :disabled="disableSubmit">
        Delete
      </b-button>
    </b-form>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator';
import controllers from '@/api/controllers';
import { failToast } from '@/utils/genericUtils';

@Component({})
export default class DeleteControllerForm extends Vue {
  @Prop(Object) readonly controller!: Controller;

  disableSubmit = false;

  onSubmit(evt: Event) {
    evt.preventDefault();
    this.disableSubmit = true;
    controllers.deleteController(this.controller).then((res) => {
      if (res.success) {
        this.$emit('delete-success');
      } else {
        failToast(this, 'Delete failed, please try again later.');
        this.disableSubmit = false;
      }
    });
  }
}
</script>
