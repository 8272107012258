<template>
  <div>
    <h2>Reports</h2>
    <hr />
    <b-card title="Inventory Report">
      <b-card-text>
        Click below to download an Excel file containing the
        <em>current</em> part inventory.
      </b-card-text>
      <vue-excel-xlsx
        class="btn btn-danger"
        :data="parts"
        :columns="inventoryColumns"
        :file-name="'Inventory_Report(' + getDateTime() + ')'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
      >
        <i class="fa fa-download" /> Inventory Report
      </vue-excel-xlsx>
    </b-card>
    <br />

    <b-card title="Parts Order Report">
      <b-card-text>
        Click below to download an Excel file containing the how many of each br
        part has been ordered.
      </b-card-text>
      <vue-excel-xlsx
        class="btn btn-danger"
        :data="orderedParts"
        :columns="orderColumns"
        :file-name="'Ordered_Parts_Report(' + getDateTime() + ')'"
        :file-type="'xlsx'"
        :sheet-name="'sheetname'"
      >
        <i class="fa fa-download" /> Ordered Parts Report
      </vue-excel-xlsx>
    </b-card>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from 'vue-property-decorator';
import parts from '../../api/parts';
import orders from '../../api/orders';
@Component({
  components: {},
})
export default class ReportsIndex extends Vue {
  parts: Part[] = [];
  orderedParts: Part[] = [];

  orders: Order[] = [];

  inventoryColumns = [
    {
      label: 'Part #',
      field: 'partKey',
    },
    {
      label: 'Part Name',
      field: 'name',
    },
    {
      label: 'Part Category',
      field: 'categoryName',
    },
    {
      label: 'Part Vendor',
      field: 'vendorName',
    },
    {
      label: 'Quantity',
      field: 'quantity',
    },
    {
      label: 'Price',
      field: 'price',
      dataFormat: (value: number) => {
        return Number(value).toLocaleString('us-US', {
          style: 'currency',
          currency: 'USD',
        });
      },
    },
  ];

  orderColumns = [
    {
      label: 'Part #',
      field: 'partKey',
    },
    {
      label: 'Part Name',
      field: 'name',
    },
    {
      label: 'Quantity',
      field: 'quantityOrdered',
    },
  ];

  getDateTime() {
    const date = new Date();
    return (
      date.getFullYear() +
      '-' +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      '-' +
      ('0' + date.getDate()).slice(-2) +
      ' ' +
      ('0' + date.getHours()).slice(-2) +
      '_' +
      ('0' + date.getMinutes()).slice(-2) +
      '_' +
      ('0' + date.getSeconds()).slice(-2)
    );
  }

  update() {
    parts.getParts().then((res) => {
      // auth check
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.parts = res.data;
    });
    orders.getOrdersForReport().then((res) => {
      // auth check
      if (res.status === 403) {
        this.$router.push('/forbidden').catch(() => {});
      }
      this.orders = res.data;

      this.orderedParts = this.orders
        .map((order) => {
          return order.parts;
        })
        .flat();
      this.orderedParts = [
        ...this.orderedParts
          .reduce((r, o) => {
            const key = o.partKey;

            const item =
              r.get(key) ||
              Object.assign({}, o, {
                quantityOrdered: 0,
              });

            item.quantityOrdered += o.quantityOrdered;

            return r.set(key, item);
          }, new Map())
          .values(),
      ];
    });
  }

  mounted() {
    this.update();
  }
}
</script>
